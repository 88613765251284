<template>
	<div class="bannerWap">
		<div class="swiperWap">
			<div class="swiper-container-banner swiper">
				<!-- 这边的两个方法是鼠标移入和移出 -->
				<div class="swiper-wrapper-banner swiper-wrapper" v-if="listData">
					<div class="swiper-slide category" v-for="(item,index) in listData" :key="index">
						<img :src="item.image" @click="bannerClick(item)" style="cursor: pointer;">
					</div>
				</div>

			</div>
		</div>
		<!-- 如果需要导航按钮 -->
		<div class="swiper-button-prev swiper-button-prev-banner">
			<img src="@/assets/leftLv.png" alt="">
		</div>
		<!--左箭头。如果放置在swiper外面，需要自定义样式。-->
		<div class="swiper-button-next swiper-button-next-banner">
			<img src="@/assets/rightLv.png" alt="">
		</div>
		<!--右箭头。如果放置在swiper外面，需要自定义样式。-->
		<div class="number">{{listIndex}}/{{listData.length}}</div>
	</div>
</template>

<script>
	import Swiper, {
		Pagination,
		Navigation,
		Autoplay
	} from 'swiper'
	Swiper.use([Pagination, Navigation, Autoplay])
	import "swiper/swiper-bundle.min.css"; //导入核心 Swiper 样式

	import {
		bannerList,
		bannerClick
	} from "@/api/content.js"
	export default {
		name: "bannerWap",
		data() {
			return {
				listData: [],
				listIndex: 1
			}
		},
		computed: {
			language() {
				if (this.$store.state.i18n.lang == 'zh') {
					return 'zh'
				} else if (this.$store.state.i18n.lang == 'en') {
					return 'en'
				}else{
					return 'vi'
				}
			}
		},
		methods: {
			async bannerClick(data) {
				bannerClick({
					id: Number(data.id)
				})
				if (data.redirect_type === 1 && data.redirect_url!='') {
					//跳转到 详情页
					this.$router.push('/commodityDetails?id=' + data.redirect_url)
				}
			},
			async bannerList() {
				let {
					data
				} = await bannerList({
					language: this.language,
					type: 1,
					show_location: 0,
				})
				if(data){
					this.listData = data
				}
				return true
			}
		},
		created() {

		},
		async mounted() {
			await this.bannerList()
			let _this = this
			new Swiper('.swiper-container-banner', {
				slidesPerView: 4,
				slidesPerGroup: 1,
				autoplay: true,
				loop: true,
				navigation: {
					nextEl: '.swiper-button-next-banner',
					prevEl: '.swiper-button-prev-banner',
				},
				on: {
					slideChangeTransitionEnd: function() {
						_this.listIndex = this.activeIndex
					},
				},
			})
		}
	};
</script>

<style scoped>
	.bannerWap {
		width: 1300px;
		margin-top: 19px;
		position: relative;
	}

	.swiperWap {
		overflow: hidden;
		position: relative;
		/* margin-top: 19px; */
	}

	.swiperWap::after {
		content: '';
		width: 205px;
		height: 281px;
		background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(216, 216, 216, 0.00) 100%);
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
	}

	.swiperWap::before {
		content: '';
		width: 204px;
		height: 280px;
		transform: scaleX(-1);
		background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(216, 216, 216, 0.00) 100%);
		position: absolute;
		right: 0;
		top: 0px;
		z-index: 10;
	}

	.swiper-container {
		width: 1300px;
		height: 280px;
		position: relative;
	}

	.swiper-button-prev-banner::after,
	.swiper-button-next-banner::after {
		content: none;
	}

	.swiper-button-next-banner img,
	.swiper-button-prev-banner img {
		width: 32px;
		height: 32px;
	}

	.swiper-button-next-banner,
	.swiper-button-prev-banner {
		width: 32px;
		height: 32px;
		margin-top: -16px;
		z-index: 10;
	}

	.swiper-button-next-banner {
		right: 40px;
	}

	.swiper-button-prev-banner {
		left: 40px;
	}

	.category img,
	.category {
		width: 350px;
		height: 280px;
		display: block;
		margin: 0 25px;
		border-radius: 8px;
	}
	.number{
		width: 64px;
		height: 28px;
		background: #FFFFFF;
		border: 1px solid rgba(229,229,229,1);
		border-radius: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 19px auto 24px auto;
	}
</style>
