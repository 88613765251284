<template>
	<div class="home">
		<bannerWap ref='bannerWap'></bannerWap>
		<div class="one">
			<div>
				<img src="@/assets/resetPassword/01.png" alt="">{{i18n('146')}}
			</div>
			<div>
				<img src="@/assets/resetPassword/02.png" alt="">{{i18n('147')}}
			</div>
			<div>
				<img src="@/assets/resetPassword/03.png" alt="">{{i18n('148')}}
			</div>
			<div>
				<img src="@/assets/resetPassword/04.png" alt="">{{i18n('149')}}
			</div>
		</div>
		<div class="title">
			<div class="left">
				{{i18n('83')}}<span>{{i18n('150')}}</span>
			</div>
			<div class="right">
				{{i18n('151')}}
				<img src="@/assets/rightLv.png" alt="">
			</div>
		</div>
		<div class="spList">
			<div class="item" v-for="(item,index) in jxhh" :key="index">
				<commodity :data='item'></commodity>
			</div>
		</div>
		<div class="two">
			<!-- <img src="@/assets/home/01.jpg" alt="" v-if="lang=='zh'">
			<img src="@/assets/home/02.jpg" alt="" v-if="lang=='zh'">
			<img src="@/assets/home/03.jpg" alt="" v-if="lang=='zh'">
			<img src="@/assets/home/05.png" alt="" v-if="lang=='en'">
			<img src="@/assets/home/06.png" alt="" v-if="lang=='en'">
			<img src="@/assets/home/07.png" alt="" v-if="lang=='en'">
			<img src="@/assets/home/08.jpg" alt="" v-if="lang=='vie'">
			<img src="@/assets/home/09.jpg" alt="" v-if="lang=='vie'">
			<img src="@/assets/home/10.jpg" alt="" v-if="lang=='vie'"> -->
			<img src="@/assets/home/05.png" alt="">
			<img src="@/assets/home/06.png" alt="">
			<img src="@/assets/home/07.png" alt="">
		</div>
		<div class="title">
			<div class="left">
				{{i18n('84')}}<span>{{i18n('152')}}</span>
			</div>
			<div class="right">
				{{i18n('151')}}
				<img src="@/assets/rightLv.png" alt="">
			</div>
		</div>
		<div class="spList">
			<div class="item" v-for="(item,index) in tjcx" :key="index">
				<commodity :data='item'></commodity>
			</div>
		</div>
		<div class="three">
			<img src="@/assets/home/04.png" alt="">
		</div>
		<div class="title">
			<div class="left">
				{{i18n('85')}}<span>{{i18n('153')}}</span>
			</div>
			<div class="right">
				{{i18n('151')}}
				<img src="@/assets/rightLv.png" alt="">
			</div>
		</div>
		<div class="spList">
			<div class="item" v-for="(item,index) in ydsp" :key="index">
				<commodity :data='item'></commodity>
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	import {
		commodityHome
	} from "@/api/commodity.js"
	import foot from "@/components/topBot/footer.vue"
	import commodity from "@/components/commodity.vue"
	import bannerWap from "./components/bannerWap.vue"
	export default {
		name: "home",
		components: {
			bannerWap,
			foot,
			commodity
		},
		data() {
			return {
				tjcx: [], //特价商品
				ydsp: [], //预定商品
				jxhh: [] //精选好货
			}
		},
		methods: {
			async getItems() {
				let params = {
					type: 1, //1精选好货  //2特价商品  //3预定商品
					page_num: 1,
					page_size: 10
				}
				this.jxhh = await this.commodityHome(params)
				params.type = 2
				params.page_size = 5
				this.tjcx = await this.commodityHome(params)
				params.type = 3
				params.page_size = 10
				this.ydsp = await this.commodityHome(params)
			},
			//获取商品列表
			async commodityHome(params) {
				let {
					data
				} = await commodityHome(params)
				return data
			},
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			},
			seleLoca() {
				return this.$store.state.serviceArea.seleLoca
			},
		},
		watch: {
			seleLoca: {
				handler: function() {
					//需要更新数据
					this.getItems()
					this.$refs.bannerWap.bannerList()

				},
				deep: true
			},
			lang() {
				this.$refs.bannerWap.bannerList()
			}
		},
		mounted() {
			this.getItems()
			let lang = this.$route.query.lang
			if(lang){
				this.$store.commit('i18n/setLang', lang)
			}
		}
	};
</script>

<style scoped>
	.two,.three{
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		margin-bottom: 48px;
	}
	.two img{
		width: 378px;
		height: 152px;
		border-radius: 8px;
		overflow: hidden;
		display: block;
	}
	.three img{
		width: 1200px;
		height: 153.36px;
	}
	.spList {
		width: 1200px;
		margin: 0 auto;
		margin-bottom: 34px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.spList .item {
		margin-bottom: 14px;
	}

	.title {
		width: 1200px;
		margin: 0 auto;
		margin-bottom: 26px;

		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.title .left {
		font-family: PingFangSC-Medium;
		font-size: 24px;
		color: #1D2129;
		font-weight: 500;
	}

	.title .left span {
		margin-left: 7px;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #56AB7B;
		text-align: center;
		font-weight: 500;
	}

	.title .right {
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #56AB7B;
		text-align: center;
		font-weight: 400;
		display: flex;
		align-items: center;
	}

	.title .right img {
		width: 20px;
		height: 20px;
	}

	.home {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow-y: scroll;
	}

	.one {
		width: 1200px;
		height: 66px;
		margin: 0 auto;
		background-image: linear-gradient(180deg, rgba(181, 213, 143, .2) 0%, rgba(86, 171, 123, .2) 100%);
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		margin-bottom: 32px;
	}

	.one div {
		display: flex;
		align-items: center;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #017E3D;
		text-align: center;
		font-weight: 500;
	}

	.one div img {
		margin-right: 17px;
		width: 36px;
		height: 36px;
		display: block;
	}
</style>
